@import '@/styles/artifacts.scss';





































.wizard-button {
  outline: none;
  border: 2px solid colorVodafone(white);
  border-radius: 5px;
  padding: 10px 12px;
  background-color: colorVodafone(vodafone-red);
  color: colorVodafone(white);
  font-size: fontSize(fs-base);
  font-weight: 600;
  line-height: lineHeight(lh-reset);
  @include textOverflow();
  transition: all 0.3s ease-in-out;
  &:focus {
    box-shadow: none;
  }
}
.inactive {
  border-color: colorVodafone(white);
  background-clip: colorVodafone(light-grey);
  color: colorVodafone(white);
}
.wizard-button-secondary {
  background-color: colorVodafone(light-grey);
  color: colorVodafone(black);
}
.wizard-button-disabled {
  background-color: colorVodafone(vodafone-grey);
  color: colorVodafone(black);
}
