@import '@/styles/artifacts.scss';










































.form-message {
  position: relative;
  border: 1px solid;
  border-radius: 3px;
  padding: 10px 10px 10px 45px;
  border-color: colorVodafone(aqua-blue);
  border-left: 5px solid colorVodafone(aqua-blue);
  min-height: 47px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.form-message.error {
  border-color: colorVodafone(vodafone-red);
}
.form-message.warning {
  border-color: colorVodafone(fresh-orange);
}
.form-message.success {
  border-color: colorVodafone(digital-green);
}
.form-message::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  mask: 10px center/25px auto no-repeat;
  background-color: colorVodafone(aqua-blue);
  mask-image: url('../../../assets/images/toaster/info.svg');
}
.form-message.error::after {
  background-color: colorVodafone(vodafone-red);
  // TODO: currently the exact icon is not available as in https://cdn-assets-eu.frontify.com/local/vodafone/eyJwYXRoIjoiXC9wdWJsaWNcL3VwbG9hZFwvc2NyZWVuc1wvMlwvMjc1OGVhNjA1MTdmNTMyNWUxOTljNTY5Y2ZiYmYwYmQtMTU2NTI3MDE1My5zdmcifQ:vodafone:YDsWUIaEn3RPaa2C234SPA_q6PIngZt_Bj3uBcNCQlU?width=2400
  mask-image: url('../../../assets/images/toaster/exclamation-mark.svg');
}
.form-message.warning::after {
  background-color: colorVodafone(fresh-orange);
  mask-image: url('../../../assets/images/toaster/warning.svg');
}
.form-message.success::after {
  background-color: colorVodafone(digital-green);
  mask-image: url('../../../assets/images/toaster/smiley.svg');
}

.form-message .headline {
  font-weight: fontWeight(regular-bold);
  font-size: fontSize(fs-100);
}
