@import '@/styles/artifacts.scss';














































































.pagination {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;

  & .control {
    display: inline-block;
    width: 1.5ch;
  }

  & > ol {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      display: inline;
      margin: 0 2px 0 2px;
    }
    .highlighed {
      box-shadow: 0 0 0 2px colorVodafone(aqua-blue);
    }
  }
}
