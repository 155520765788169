@import '@/styles/artifacts.scss';



































































































































.skeleton-enter-active,
.skeleton-leave-active {
  transition: opacity 150ms;
}
.skeleton-enter,
.skeleton-leave-to {
  opacity: 0;
}
