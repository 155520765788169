@import '@/styles/artifacts.scss';



























































































































































































































.ui-table {
  overflow-y: auto;
  max-width: 100%;

  .table {
    width: 100%;
    color: colorVodafone(black);
    border-collapse: collapse;
    font-size: fontSize(fs-100);

    &.fixed {
      table-layout: fixed;
    }

    .cell {
      padding: 10px;

      .sortable {
        cursor: pointer;
        user-select: none;
      }
    }

    .head-cell {
      font-size: fontSize(fs-50);
    }

    .body-row {
      border-bottom: 1px solid colorVodafone(light-grey);
      &:first-child {
        border-top: 1px solid colorVodafone(light-grey);
      }
    }

    .sort {
      display: inline-flex;
      align-items: center;
      gap: 0.5ch;

      .label {
        @include textOverflow();
        font-weight: fontWeight(regular);
        color: colorVodafone(vodafone-red);
      }

      &::after {
        display: block;
        width: 8px;
        height: 1.5em;
      }

      &.ASC::after {
        content: url(./assets/arrow-asc.svg);
      }

      &.DESC::after {
        content: url(./assets/arrow-desc.svg);
      }
    }
  }
}
