@import '@/styles/artifacts.scss';


































































.ui-input-switch {
  display: flex;
  align-items: center;
  cursor: pointer;

  --left-color: #{colorVodafone(grey)};
  --right-color: #{colorVodafone(digital-green-active)};
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.knob {
  position: relative;
  display: inline-block;
  margin: 4px;
  border-radius: 0.75em;
  height: 1.5em;
  width: 2.5em;
  background-color: var(--left-color);
  transition: 0.4s;
}

.knob::before {
  content: '';
  position: absolute;
  left: 0.15em;
  top: 0.15em;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  background-color: colorVodafone(white);
  transition: 0.4s;
}

.knob:hover::before {
  background-color: colorVodafone(shaded);
}

.knob::after {
  position: absolute;
  left: 0.3em;
  top: 0.3em;
  box-sizing: border-box;
  border: 0.15em solid colorVodafone(vodafone-red);
  border-radius: 50%;
  height: 0.9em;
  width: 0.9em;
  transition: 0.4s;
}

.error > .knob::after {
  content: '';
}

input:checked + .knob {
  background-color: var(--right-color);
}

input:checked + .knob::before,
input:checked + .knob::after {
  transform: translateX(1em);
}
