@import '@/styles/artifacts.scss';





































































.label {
  display: block;
  color: colorVodafone(black);
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-100);
}
.label-description {
  color: colorVodafone(black);
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-100);
}
.p {
  margin: 0;
}
.error {
  color: colorVodafone(vodafone-red);
}
.single-line {
  @include textOverflow();
}
