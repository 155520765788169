@import '@/styles/artifacts.scss';



































.button {
  flex: 0 0 auto;
  display: block;
  box-sizing: border-box;
  height: 100%;
  padding: 18px 10px;
  overflow: hidden;
  color: colorVodafone(black);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: colorVodafone(vodafone-red);
  }

  &.active {
    color: colorVodafone(vodafone-red);
  }
}
