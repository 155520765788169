@import '@/styles/artifacts.scss';






































































































.input-wrapper {
  position: relative;
  width: 100%;
}

.input-text {
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
  width: 100%;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 10px;
  transition: border-color 0.3s;
  height: 48px;
}

.input-text.error {
  border-color: colorVodafone(vodafone-red);
}

.input-text:focus {
  border-color: colorVodafone(vodafone-red);
  outline: none;
  background-color: colorVodafone(white);
}

/* Clear button styling */
.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.clear-button:hover {
  color: rgba(0, 0, 0, 0.7);
}
