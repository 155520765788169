@import '@/styles/artifacts.scss';

















































































.input-checkbox {
  position: relative;
  display: block;
  padding-left: 1.5em;
  min-height: 1.5em;
  color: colorVodafone(vodafone-dark-grey);
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-100);
  cursor: pointer;
  user-select: none;
}
.input-checkbox.disabled {
  cursor: inherit;
  opacity: 0.5;
}

.input-checkbox.disabled.full-opacity {
  opacity: 1;
}

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0.25em;
  left: 0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 1em;
  width: 1em;
  background-color: colorVodafone(shaded);
}
.checkbox:checked ~ .checkmark {
  background-color: colorVodafone(vodafone-red);
}
.checkbox:indeterminate:not(.implicit) ~ .checkmark {
  background-color: colorVodafone(turquoise);
}
.checkbox.implicit:not(.checked) ~ .checkmark {
  background-color: colorVodafone(vodafone-red);
  opacity: 0.5;
}
.checkbox.hidden ~ .checkmark {
  visibility: hidden;
}

.checkbox:checked ~ .checkmark::after,
.checkbox.implicit ~ .checkmark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border: solid colorVodafone(white);
  border-width: 0 4px 4px 0;
  width: 6px;
  height: 11px;
  transform-origin: right;
  transform: translateY(1.5px) rotate(45deg) scale(0.63);
}
.checkbox:indeterminate:not(.implicit) ~ .checkmark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border: solid colorVodafone(white);
  border-width: 0 2px 0px 0;
  width: 6px;
  height: 11px;
  transform-origin: right;
  transform: translateY(3.5px) rotate(90deg);
}
