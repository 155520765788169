@import '@/styles/artifacts.scss';



































































.button-container {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 15px;
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-25);

  &.inline {
    display: inline-flex;
  }

  &.button-disabled {
    color: colorVodafone(black) !important;
    cursor: not-allowed;
    opacity: 0.6; // Optional: Adds a faded effect
  }
}

.label {
  color: colorVodafone(vodafone-red);
  font-size: fontSize(fs-100);
  white-space: nowrap;
  margin: 0 5px;

  &.fixed {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  background: colorVodafone(white);
  border-radius: 15px;
  width: fit-content;

  &.fixed {
    width: 160px;
  }

  :hover > & {
    box-shadow: 0 0 0 2px colorVodafone(vodafone-red);
  }
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
