@import '@/styles/artifacts.scss';









































.input-textarea {
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  min-height: 6em;
  max-height: 400px;
  transition: border-color 0.3s;
  resize: vertical;
}
.input-textarea:focus {
  border-color: colorVodafone(vodafone-red);
  outline: none;
}
.input-textarea.error {
  border-color: colorVodafone(vodafone-red);
}
