@import '@/styles/artifacts.scss';




































































































.input-select {
  &.error {
    border-color: colorVodafone(vodafone-red) !important;
  }

  &.error ::v-deep .vs__dropdown-toggle {
    border-color: colorVodafone(vodafone-red);
  }

  &.error ::v-deep .vs__selected {
    color: colorVodafone(vodafone-red);
  }

  &.error ::v-deep .vs__search::placeholder {
    color: colorVodafone(vodafone-red);
  }

  &.error ::v-deep .vs__open-indicator {
    fill: colorVodafone(vodafone-red);
  }
}

.input-select.vs--open,
.input-select:focus {
  border-color: colorVodafone(vodafone-red);
  outline: none;
  background-color: colorVodafone(white);
}

.input-select ::v-deep .vs__dropdown-option--highlight {
  background-color: colorVodafone(select-hover);
  color: colorVodafone(black);
}

.input-select.v-select {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
  border: 1px solid colorVodafone(light-grey);
  border-radius: 3px;
  height: 48px;
}

.input-select ::v-deep .vs__open-indicator {
  width: 18px;
  height: 34px;
}

.input-select ::v-deep .vs__dropdown-toggle {
  border: none;
  height: 100%;
}

.input-select.vs--open {
  border-color: colorVodafone(vodafone-red);
  outline: none;
  background-color: colorVodafone(white);
}

.input-select ::v-deep .vs__dropdown-option--highlight {
  color: colorVodafone(vodafone-red);
  background: none;
}

// changes color of down chevron
.input-select ::v-deep svg.vs__open-indicator path:first-child {
  stroke: colorVodafone(grey);
}

// padding of placeholder
.input-select ::v-deep.vs__search {
  padding: 2px 10px;
}

// adjusts the vertical alignment of the buttons (baseline)
.input-select ::v-deep .vs__actions {
  padding: 7px 6px 0 3px;
}

// increases the click area of the clear button
.input-select ::v-deep .vs__clear {
  height: 100%;
}

.input-select ::v-deep .vs__clear:hover svg path {
  stroke: colorVodafone(vodafone-red);
}

.input-select ::v-deep .vs__selected {
  width: max-content;
  display: inline-block;
  padding-top: 9px;
  padding-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-select ::v-deep .vs__selected-options {
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
}
