@import '@/styles/artifacts.scss';



































































.datepicker-container {
  display: flex;
  align-items: center; // Align the label and datepicker horizontally
}

.datepicker-label {
  margin-right: 1em; // Space between the label and the date picker
  font-weight: bold;
  color: colorVodafone(black);
}

.datepicker-wrapper {
  display: flex;
  align-items: center; // Ensure the elements are vertically aligned
}

.date {
  color: colorVodafone(black);
}
.calendar-icon {
  margin-right: 0.5em;
  margin-bottom: -2px;
}
.clear {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 2px;
}
.clear-icon {
  margin-left: 0.5em;
  margin-bottom: -3px;
}
.trigger:hover {
  color: colorVodafone(vodafone-red);
}
.trigger:hover .icon {
  stroke: colorVodafone(vodafone-red);
}
.picker ::v-deep .vdp-datepicker__calendar {
  border: none;
}
.picker ::v-deep .vdp-datepicker__calendar header .prev:not(.disabled):hover,
.picker ::v-deep .vdp-datepicker__calendar header .next:not(.disabled):hover,
.picker ::v-deep .vdp-datepicker__calendar header .up:not(.disabled):hover {
  background-color: colorVodafone(lighter);
}
.picker ::v-deep .vdp-datepicker__calendar .cell.selected {
  color: colorVodafone(white);
  background-color: colorVodafone(vodafone-red);
}
.picker ::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.picker ::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.picker ::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border-color: colorVodafone(vodafone-red);
}
