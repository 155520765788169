@import '@/styles/artifacts.scss';


























.page {
  display: inline-block;
}
.pagination {
  margin: 0 2px 0 2px;
}
