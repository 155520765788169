@import '@/styles/artifacts.scss';



















































.panel-container {
  box-sizing: border-box;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 20px;
  background-color: colorVodafone(white);
  color: colorVodafone(black);
}
.stretch {
  width: 100%;
  height: 100%;
}
