@import '@/styles/artifacts.scss';

































































































































































































.bar {
  position: relative;
  font-size: fontSize(fs-100);
  margin: -20px;
  padding: 0 20px;
}

.scroll-button-wrapper {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 20;
  &.scroll-left {
    left: 0;
  }
  &.scroll-right {
    right: 0;
  }
}

.scroll-button {
  height: 100%;
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  color: colorVodafone(grey);
  line-height: 1.3;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    color: colorVodafone(vodafone-red);
  }

  &:focus {
    outline: none;
  }
  .fits & {
    display: none;
  }
}

.scroll-wrapper {
  overflow: hidden;
}

.scroll-spacer {
  display: flex;
}

.scroll {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  transition: left 0.5s;
}

.indicator {
  position: absolute;
  height: 2px;
  z-index: 10;
  background-color: colorVodafone(vodafone-red);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  &.on {
    opacity: 1;
  }
  &.left {
    transition: left 0.2s, right 0.15s 0.05s;
  }
  &.right {
    transition: left 0.15s 0.05s, right 0.2s;
  }
  .top & {
    top: 0;
  }
  .bottom & {
    bottom: 0;
  }
}
